<template>
  <div>
    <v-dialog v-model="inconclusivePopup" width="1400">
      <v-card v-if="inconclusive" class="bg-canvas px-5 pt-10">
        <v-card-text>
          <strong>Du kan ikke tildele, da der er to servicevarianter med samme pris.</strong>
          <br /><br />
          Du skal nu gå tilbage til kravspecifikationen og genlæse beskrivelserne for de to services under fanen "Services".
          Her skal du da afgøre, hvilken af de to services, som bedst imødekommer dit indkøbsbehov og tilvælge denne.
          Den anden service kan du da ’fravælge’, men husk at angive en saglig begrundelse for dit fravalg.
          Du kan da gå til evalueringen igen og tildele vinderen på ny.
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="mb-4 primary-button"
            @click="closeDialog()"
          >
            Luk
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <p v-if="tender.state === TenderStateEnum.Evaluate">
      Du skal nu indtaste de sidste informationer for at afslutte evaluering og
      identificere den vindende servicevariant. Følg trinnene herunder,
      hvorefter du kan tildele til billigste servicevariant (placeres øverst i
      listen herunder). Først når du trykker "Tildel" vil der sendes en besked
      afsted til leverandøren. Inden da har du mulighed for at gennemse de
      dokumenter som medsendes, hvori dine valg er afspejlet.
      <SwitchToDraft :tender="tender" @switched="emit('updateTab', 'material')" />
    </p>
    <v-container fluid>
      <v-row>
        <v-col>
          <div class="d-flex align-center">
            <h2 class="mt-0">Evaluering</h2>
            <DimsTooltip>
              <p>
                Nu vises alle servicevarianter hver for sig. Bemærk en
                servicevariant kan forekomme flere gange, hvis den tilbydes på
                flere serviceniveauer, da serviceniveauer, der ligger over det niveau,
                som Kunden har behov for, medtages. Har du fx stillet krav om serviceniveau
                'Sølv' og en servicevariant (som opfylder dine krav) tilbydes både på
                serviceniveau 'Sølv' og 'Guld' (dvs. bedre) vil begge figurere i listen herunder.
                Prisen pr. servicevariant vil afgøre, hvilken du skal tildele. Hvis prisen er ens,
                skal du tildele til den, som svarer til det serviceniveau, du har valgt,
                jf. bilag D, punkt 3.4.
                <br />
                <br />
                Hvis to forskellige servicevarianter har samme pris, kan du ikke tildele.
                Du skal da gå tilbage til kravspecifikationen og genlæse beskrivelserne for de to
                services under fanen "Services". Her skal du da afgøre, hvilken af de to services,
                som bedst imødekommer dit indkøbsbehov og tilvælge denne. Den anden service kan du
                da ’fravælge’, men husk at angive en saglig begrundelse for dit fravalg. Du kan da
                gå til evalueringen igen og tildele til vinderen.
              </p>
            </DimsTooltip>
            <v-chip class="ml-2">{{ allVariants.length }}</v-chip>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p v-if="allVariants.length === 0">Der er ingen servicevarianter, der opfylder dine krav og valg i kravspecifikationen. Du kan sætte kravspecifikationen tilbage til kladde og genoverveje dine valg.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <template v-if="conditionalVariants.length">
            <v-card class="scroll" color="canvas pa-3" flat>
              <v-card
                v-for="(variant) in conditionalVariants"
                :key="variant.id"
                :class="selectedVariant?.id === variant.id ? 'card-selected' : 'card'"
                :color="bestVariant(variant) ? '#D4E3DC' : ''"
                style="overflow: hidden"
                @click="selectVariant(variant)"
              >
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="auto">
                      <WinnerTag
                        v-if="bestVariant(variant)"
                        :isWinner="isReadOnly"
                        :avatarSize="55"
                        class="winner-tag"
                        tagClass="pl-1 pt-1"
                      />
                      <QuestionIcon
                        :state="variant.readyForCalculation() ? QuestionStateEnum.COMPLETE : QuestionStateEnum.WARNING"
                        :color="variant.readyForCalculation() ? 'primary' : 'accent'"
                        :size="18"
                        :style="{ opacity: bestVariant(variant) ? 0 : 1 }"
                      />
                    </v-col>
                    <v-col>{{ variant.serviceVariantName_0219 }} ({{ variant.supplierName }}, {{ products.find(x => x.id === variant.productId)?.name }})</v-col>
                    <v-col cols="auto"><PimIntegrationServiceLevelChips :serviceLevels="[variant.serviceLevel_0219 ?? 'ukendt']" /></v-col>
                    <v-col cols="auto">
                      <span class="total-price">{{ $format.currency2(variant.priceResult?.price ?? undefined) }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card>
          </template>
          <template v-if="unconditionalVariants.length">
            <h3>Opfylder ikke dine sikkerhedskrav jf. risikovurdering</h3>
            <v-card class="scroll" color="canvas pa-3 mt-3">
              <v-card
                v-for="variant in unconditionalVariants"
                :key="variant.id"
                :color="'#ecc8d3'"
                :class="selectedVariant?.id === variant.id ? 'card-selected' : 'card'"
                @click="selectVariant(variant)"
              >
                <v-card-text>
                  <v-row>
                    <v-col>{{ variant.serviceVariantName_0219 }} ({{ variant.supplierName }})</v-col>
                    <v-col cols="auto"><PimIntegrationServiceLevelChips :serviceLevels="[variant.serviceLevel_0219 ?? 'ukendt']" /></v-col>
                    <v-col cols="auto">
                      <span class="total-price">{{ $format.currency2(variant.priceResult?.price ?? undefined) }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card>
          </template>
        </v-col>
        <v-col v-if="selectedVariant">
          <EvaluationVariant
            :tender="tender"
            :bestVariant="allVariants.find((x) => bestVariant(x))"
            :selectedVariant="selectedVariant"
            :selectedProduct="selectedProduct"
            :initialEducationCountsFT="initialEducationCountsFT"
            :initialEducationCountsSVC="initialEducationCountsSVC"
            :initialCalculationUnitCounts="initialCalculationUnitCounts"
            :calculationUnitCounts="productCalculationUnitsProp"
            :relevantCalculationUnits="relevantCU"
            :allVariants="allVariants"
            :inconclusive="inconclusive"
            @implementationType="onChangeIT"
            @educationCountsService="onChangeSVC"
            @educationCountsAddon="onChangeFT"
            @calculationUnitCounts="onChangeCU"
            @updateTab="emit('updateTab', $event)"
            @selectVariant="selectVariant($event)"
          >
          </EvaluationVariant>
        </v-col>
      </v-row>
    </v-container>
    <LoadingSpinner loadingMessage="Henter data og beregner priser" :visible="!loaded" />
  </div>
</template>

<script setup lang="ts">
import { useBackend, TenderStateEnum, DimsTooltip, CalculationUnitValue,
  OrganizationInfo, DeepQuestionnaire, BatchUpdateDirectOfferRequest, QuestionStateEnum, PriceResult, SwitchToDraft } from '@dims/components';
import { ref, computed, watch, onMounted } from 'vue';
import { Product0219 } from '@/models/PIM/Product0219';
import { ExtendedVariant } from '@/models/PIM/Variant0219';
import { Tender0219, SystemTypesEnum, UpdateTenderRequest0219 } from '@/models';
import { PriceParameterSet } from '@/models/PriceParameter';
import { ManagementInformation0219 } from '@/models/PIM/ManagementInformation0219';
import { PublicIntegration0219 } from '@/models/PIM/PublicIntegration0219';
import { KombitIntegration0219 } from '@/models/PIM/KombitIntegration0219';
import { IntegrationPublicComponent } from '@/models/PIM/GlobalLists/IntegrationPublicComponent';
import { IntegrationKombit } from '@/models/PIM/GlobalLists/IntegrationKombit';
import { deepQuestionnaireHelper0219 } from '@/services/deepQuestionnaireHelper0219';
import EvaluationVariant from './EvaluationVariant.vue';
import WinnerTag from './WinnerTag.vue';
import PimIntegrationServiceLevelChips from '../Specification/PimIntegration/PimIntegrationServiceLevelChips.vue';
import { EducationCount } from '@/models/EducationCount';
import { CreateDirectOfferRequest0219, DirectOffer0219, ImplementationType, UpdateDirectOfferRequest0219 } from '@/models/DirectOffer';

interface ProductReferences_0219 {
  readonly managementInfo_0219: ManagementInformation0219[];
  readonly integrationPublicComponent_0219: PublicIntegration0219[];
  readonly kombitIntegration_0219: KombitIntegration0219[];
}

const emit = defineEmits<{ updateTab: [string] }>();
const { tender } = defineProps<{ tender: Tender0219 }>();
const backend = useBackend();
const products = ref<Product0219[]>([]);
const allVariants = ref<ExtendedVariant[]>([]);
const contractLength = ref(0);
const contractDate = ref('');
const option1 = ref(false);
const option2 = ref(false);
const option3 = ref(false);
const option3Count = ref(0);
const juniorConsultHours = ref(0);
const standardConsultHours = ref(0);
const seniorConsultHours = ref(0);
const chiefConsultHours = ref(0);
const lisFrequency = ref('');
const flisFrequency = ref('');
const publicIntegrations = ref<PublicIntegration0219[]>([]);
const kombitIntegrations = ref<KombitIntegration0219[]>([]);
const useCases = ref<string[]>([]);
const suppliers: OrganizationInfo[] = [];

const selectedVariant = ref<ExtendedVariant | null>(null);
const selectedProduct = ref<Product0219 | null>(null);
const selectedOffer = ref<DirectOffer0219 | null>(null);
const productCalculationUnitsProp = ref<CalculationUnitValue[]>([]);
const educationCountServiceType2 = ref<EducationCount|null>(null);
const initialEducationCountsFT = ref<EducationCount[]>([]);
const initialEducationCountsSVC = ref<EducationCount>({});
const initialCalculationUnitCounts = ref<CalculationUnitValue []>([]);
const loaded = ref(false);
const questionnaire = ref<DeepQuestionnaire>();
const service = 'Service';
const inconclusive = ref(false);
const inconclusivePopup = ref(false);

const relevantCU = computed(():CalculationUnitValue[] => {
  if (selectedVariant.value && selectedProduct.value) { return relevantCalculationUnits(selectedVariant.value, selectedProduct.value); }
  return [];
});

function closeDialog() {
  inconclusivePopup.value = false;
}

function priceParameterSet(variant: ExtendedVariant):PriceParameterSet {
  const product = products.value.find((x) => x.id === variant.productId);
  return {
    variantId: variant.id,
    productId: product?.id,
    serviceName: `${variant.serviceVariantName_0219} (${variant.supplierName})`,
    serviceLevel: variant.serviceLevel_0219,
    priceRegulation: product?.priceRegulation_0219,
    name: tender.description,
    contractDate: contractDate.value,
    contractLength: contractLength.value,
    implementationType: variant.directOffer?.implementationType ?? undefined,
    option1: option1.value,
    option2: option2.value,
    option3: option3.value,
    option3Count: option3Count.value,
    managementInformationProductId: variant.managementInformationProduct?.id,
    commonManagementInformationProductId: variant.commonManagementInformationProduct?.id,
    publicIntegrationIds: publicIntegrations.value.map((x) => x.id),
    kombitIntegrationIds: kombitIntegrations.value.map((x) => x.id),
    functionalAddOnIds: variant.selectedFunctionalAddOns?.filter((x) => x.selected).map((x) => x.id),
    supplierSpecificIntegrationIds: variant.selectedSupplierSpecificIntegrations?.filter((x) => x.selected).map((x) => x.id),
    educationCounts: variant.directOffer?.educationCounts ?? undefined,
    calculationUnitValues: product ? relevantCalculationUnits(variant, product) : [],
    juniorConsultHours: juniorConsultHours.value,
    standardConsultHours: standardConsultHours.value,
    seniorConsultHours: seniorConsultHours.value,
    chiefConsultHours: chiefConsultHours.value,
  };
}

async function onChangeFT(counts: EducationCount[], offer: DirectOffer0219) {
  if (loaded.value) {
    await updateOffersWithFT(counts, offer);
    initialEducationCountsFT.value = offer.educationCounts?.filter((o) => o.area !== service).map((x) => ({ ...x })) ?? [];
  }
  await refreshAllPrices();
}

async function updateOffersWithFT(counts: EducationCount[], offer: DirectOffer0219) {
  if (loaded.value) {
    loaded.value = false;
    const request: BatchUpdateDirectOfferRequest<UpdateDirectOfferRequest0219> = { updateDirectOfferRequests: [] };
    for (const v of allVariants.value.filter((x) => x.productId === selectedProduct.value?.id)) {
      if (v.directOffer) {
        const educationCountsService = offer.educationCounts?.find((e) => e.area === service);
        const newEducationCountService = { ...educationCountsService };
        newEducationCountService.refId = v.id;
        if (v.directOffer.educationCounts) {
          const oldCounts = v.directOffer.educationCounts.filter((e) => e.area !== service);
          const newCounts: EducationCount[] = [];
          for (let index = 0; index < counts.length; index += 1) {
            const newCount = counts[index];
            let oldCount = oldCounts[index];
            if (!oldCount) {
              oldCount = { ...newCount };
            } else {
              copyEducationCounts(newCount, oldCount);
            }
            newCounts.push(oldCount);
          }
          const newOffer:UpdateDirectOfferRequest0219 = {
            id: v.directOffer.id,
            educationCounts: [newEducationCountService].concat(newCounts),
          };
          request.updateDirectOfferRequests.push(newOffer);
        } else {
          const serviceCounts:EducationCount = { ...educationCountsService };
          serviceCounts.refId = v.id;
          const updated:UpdateDirectOfferRequest0219 = {
            id: v.directOffer.id,
            educationCounts: [serviceCounts].concat(counts),
          };
          request.updateDirectOfferRequests.push(updated);
        }
      }
    }
    const updatedOffers = await backend.directOfferService.updateDirectOfferBatch<DirectOffer0219, UpdateDirectOfferRequest0219>(request);
    updatedOffers.forEach((o) => {
      const variant = allVariants.value.find((v) => v.directOffer?.id === o.id);
      if (variant && variant.directOffer) {
        variant.directOffer.educationCounts = o.educationCounts;
      }
    });
    loaded.value = true;
  }
}

async function onChangeSVC(counts: EducationCount, offer: DirectOffer0219) {
  if (loaded.value) {
    await updateOffers(counts, offer);
    initialEducationCountsSVC.value = { ...offer.educationCounts?.find((o) => o.area === service) };
  }
  await refreshAllPrices();
}

async function onChangeIT(implementationType: ImplementationType | null, productId: number) {
  if (loaded.value && implementationType) {
    const variantToUpdate = allVariants.value.filter((x) => x.productId === productId && x.directOffer?.id);
    let updates: UpdateDirectOfferRequest0219[];
    if (implementationType === 'ExistingImplementation') {
      updates = variantToUpdate.map((x) => ({ id: x.directOffer?.id ?? undefined, implementationType }));
    } else {
      updates = variantToUpdate.map((x) => {
        if (educationCountServiceType2.value) { copyEducationCounts(educationCountServiceType2.value, x.directOffer?.educationCounts?.find((e) => e.area === service)); }
        return { id: x.directOffer?.id ?? undefined,
          implementationType,
          educationCounts: x.directOffer?.educationCounts ?? [] };
      });
    }
    const request = { updateDirectOfferRequests: updates };
    const updatedOffers = await backend.directOfferService.updateDirectOfferBatch<DirectOffer0219, UpdateDirectOfferRequest0219>(request);
    updatedOffers.forEach((o) => {
      const variant = allVariants.value.find((v) => v.directOffer?.id === o.id);
      if (variant && variant.directOffer) variant.directOffer.implementationType = o.implementationType;
    });
  }
  await refreshAllPrices();
}

async function onChangeCU(calculationUnitValues: CalculationUnitValue[]) {
  const update: UpdateTenderRequest0219 = { id: tender.id, data: { calculationUnitValues } };
  const updatedTender = await backend.tenderService.updateTender(update) as Tender0219;
  initialCalculationUnitCounts.value = updatedTender.data.calculationUnitValues?.map((x) => ({ ...x })) ?? [];
  productCalculationUnitsProp.value = updatedTender.data.calculationUnitValues ?? [];
  await refreshAllPrices();
}

async function updateOffers(counts: EducationCount, offer: DirectOffer0219) {
  const educationCountsFT = offer.educationCounts?.filter((e) => e.area !== service) ?? [];
  if (offer.implementationType === 'ExistingImplementation') {
    const update = {
      educationCounts: [counts].concat(educationCountsFT),
    };
    const updatedOffer = await backend.directOfferService.updateOffer<DirectOffer0219, UpdateDirectOfferRequest0219>(offer, update);
    const variant = allVariants.value.find((v) => v.directOffer?.id === updatedOffer.id);
    if (variant && variant.directOffer) {
      variant.directOffer.educationCounts = updatedOffer.educationCounts;
    }
  } else {
    // Copy to all type 2 and unselected variants.
    loaded.value = false;
    if (educationCountServiceType2.value) { copyEducationCounts(counts, educationCountServiceType2.value); }
    const request: BatchUpdateDirectOfferRequest<UpdateDirectOfferRequest0219> = { updateDirectOfferRequests: [] };
    for (const v of allVariants.value) {
      if (v.directOffer) {
        if (v.directOffer.educationCounts) {
          const oldCounts = v.directOffer.educationCounts;
          const index = v.directOffer.educationCounts.findIndex((x) => x.area === service);
          if (v.directOffer.implementationType === 'NewImplementation') {
            copyEducationCounts(counts, oldCounts[index]);
          }
          const newOffer:UpdateDirectOfferRequest0219 = {
            id: v.directOffer.id,
            educationCounts: oldCounts,
          };
          request.updateDirectOfferRequests.push(newOffer);
        } else {
          const newCounts:EducationCount = { ...counts };
          newCounts.refId = v.id;
          const updated:UpdateDirectOfferRequest0219 = {
            id: v.directOffer.id,
            educationCounts: [newCounts].concat(educationCountsFT),
          };
          request.updateDirectOfferRequests.push(updated);
        }
      }
    }
    const updatedOffers = await backend.directOfferService.updateDirectOfferBatch<DirectOffer0219, UpdateDirectOfferRequest0219>(request);
    updatedOffers.forEach((o) => {
      const variant = allVariants.value.find((v) => v.directOffer?.id === o.id);
      if (variant && variant.directOffer) {
        variant.directOffer.educationCounts = o.educationCounts;
      }
    });
    loaded.value = true;
  }
}

function copyEducationCounts(newCounts: EducationCount|undefined, oldcount: EducationCount|undefined) {
  if (oldcount && newCounts) {
    // eslint-disable-next-line no-param-reassign
    if ((newCounts.trainTheTrainer ?? -1) >= 0) oldcount.trainTheTrainer = newCounts.trainTheTrainer;
    // eslint-disable-next-line no-param-reassign
    if ((newCounts.superuserEducation ?? -1) >= 0) oldcount.superuserEducation = newCounts.superuserEducation;
    // eslint-disable-next-line no-param-reassign
    if ((newCounts.enduserEducation ?? -1) >= 0) oldcount.enduserEducation = newCounts.enduserEducation;
    // eslint-disable-next-line no-param-reassign
    if ((newCounts.enduserEducationRefresh ?? -1) >= 0) oldcount.enduserEducationRefresh = newCounts.enduserEducationRefresh;
  }
}

watch(
  () => tender.state,
  async (newState?: TenderStateEnum) => {
    if (newState === TenderStateEnum.Evaluate) {
      await fetchData();
    }
  },
);

async function fetchData() {
  loaded.value = false;
  // Load questionnarire values
  questionnaire.value = await fetchQuestionnaire();
  const { questions } = questionnaire.value;
  contractLength.value = deepQuestionnaireHelper0219.loadContractLengthIncludingYearsWithOptions(questions, deepQuestionnaireHelper0219.contractLengthQuestionId);
  contractDate.value = deepQuestionnaireHelper0219.loadDateValue(questions, deepQuestionnaireHelper0219.contractStartQuestionId);
  option1.value = deepQuestionnaireHelper0219.isYes(questions, deepQuestionnaireHelper0219.option1QuestionId);
  option2.value = deepQuestionnaireHelper0219.isYes(questions, deepQuestionnaireHelper0219.option2QuestionId);
  option3.value = deepQuestionnaireHelper0219.isYes(questions, deepQuestionnaireHelper0219.option3QuestionId);
  option3Count.value = deepQuestionnaireHelper0219.loadNumValue(questions, deepQuestionnaireHelper0219.option3CountQuestionId);
  juniorConsultHours.value = deepQuestionnaireHelper0219.loadNumValue(questions, deepQuestionnaireHelper0219.juniorConsultHoursQuestionId);
  standardConsultHours.value = deepQuestionnaireHelper0219.loadNumValue(questions, deepQuestionnaireHelper0219.standardConsultHoursQuestionId);
  seniorConsultHours.value = deepQuestionnaireHelper0219.loadNumValue(questions, deepQuestionnaireHelper0219.seniorConsultHoursQuestionId);
  chiefConsultHours.value = deepQuestionnaireHelper0219.loadNumValue(questions, deepQuestionnaireHelper0219.chiefConsultHoursQuestionId);
  lisFrequency.value = deepQuestionnaireHelper0219.loadOptionValue(questions, deepQuestionnaireHelper0219.managementInfoQuestionId);
  flisFrequency.value = deepQuestionnaireHelper0219.loadOptionValue(questions, deepQuestionnaireHelper0219.commonManagementInfoQuestionId);
  if (tender.data.systemType === SystemTypesEnum.PartI) {
    useCases.value = deepQuestionnaireHelper0219.loadUseCases(questions, deepQuestionnaireHelper0219.useCasesPartIQuestionId);
  } else if (tender.data.systemType === SystemTypesEnum.PartII) {
    useCases.value = deepQuestionnaireHelper0219.loadUseCases(questions, deepQuestionnaireHelper0219.useCasesPartIIQuestionId);
  }
  // Load variants and dependent data
  const productIds = [...new Set(tender.data.filteredVariants?.map((x) => x.productId))];
  let fetchedProducts:Product0219[] = [];
  try {
    fetchedProducts = await backend.pimDataService.fetchProductsByProductIds<Product0219>(tender.agreementName, productIds);
  } catch (error) {
    throw new Error('Det lykkedes ikke at hente produktinformationerne fra PIM. Service er måske nede, prøv igen senere.');
  }
  products.value = fetchedProducts.filter((x) => productIds.includes(x.id));
  if (!tender.data.calculationUnitValues) { tender.data.calculationUnitValues = []; }
  const productCalcUnits = tender.data.calculationUnitValues;
  productCalculationUnitsProp.value = productCalcUnits;

  const infos: ManagementInformation0219[] = [];
  const publicIntegrationsConst: PublicIntegration0219[] = [];
  const kombitIntegrationsConst: KombitIntegration0219[] = [];
  const references: Promise<ProductReferences_0219>[] = [];
  products.value.forEach((product) => {
    references.push(backend.pimDataService.fetchProductReferences<ProductReferences_0219>(product.id));
  });
  const orgIds = [...new Set(products.value.map((x) => x.supplier?.orgIDSupplier))];
  const orgPromises: Promise<void>[] = [];
  orgIds.forEach((id) => {
    if (id) { orgPromises.push(backend.organizationService.fetchOrganizationsForDirectOffer(id).then((s) => { suppliers.push(s); })); }
  });
  await Promise.all(orgPromises);

  let referenceMaps: ProductReferences_0219[] = [];
  try {
    referenceMaps = await Promise.all(references);
  } catch (error) {
    throw new Error('Det lykkedes ikke at hente produktinformationerne fra PIM. Service er måske nede, prøv igen senere.');
  }
  referenceMaps.forEach((reference) => {
    const infoRefs = reference.managementInfo_0219;
    infos.push(...(infoRefs));
    const publicIntegrationRefs = reference.integrationPublicComponent_0219;
    publicIntegrationsConst.push(...(publicIntegrationRefs));
    const kombitIntegration = reference.kombitIntegration_0219;
    kombitIntegrationsConst.push(...(kombitIntegration));
  });
  publicIntegrations.value = deepQuestionnaireHelper0219.loadTransformedPlaceholderValue<IntegrationPublicComponent, PublicIntegration0219>(
    questions,
    deepQuestionnaireHelper0219.publicIntegrationsQuestionId,
    publicIntegrationsConst,
    (g, p) => g.integrationPublicComponentId_0219 === p.integrationPublicComponent_0219?.integrationPublicComponentId_0219,
  );
  kombitIntegrations.value = deepQuestionnaireHelper0219.loadTransformedPlaceholderValue<IntegrationKombit, KombitIntegration0219>(
    questions,
    deepQuestionnaireHelper0219.kombitIntegrationsQuestionId,
    kombitIntegrationsConst,
    (g, p) => g.integrationKombitId_0219 === p.integrationKOMBIT_0219?.integrationKombitId_0219 && useCases.value.includes(p.useCase_0219?.useCaseNumber_0219 ?? ''),
  );
  productCalculationUnitsProp.value = productCalcUnits;
  initialCalculationUnitCounts.value = productCalcUnits.map((x) => ({ ...x }));
  const promises: Promise<void>[] = [];
  allVariants.value = tender.data.filteredVariants?.filter((x) => x.state === 'Accepted').map<ExtendedVariant>((x) => {
    const ev:ExtendedVariant = ({
      ...x,
      // eslint-disable-next-line no-return-assign
      priceResultFunc() { return calculatePrice(this.directOffer, this).then((r) => this.priceResult = r); },
      supplierName: products.value.find((p) => p.id === x.productId)?.supplier?.name,
      readyForCalculation() { return calcReady(this.directOffer, this); },
      managementInformationProduct: getManagementInfoProduct(x.productId, x.serviceLevel_0219 ?? '', lisFrequency.value, 'LIS', infos),
      commonManagementInformationProduct: getManagementInfoProduct(x.productId, x.serviceLevel_0219 ?? '', flisFrequency.value, 'FLIS', infos),
    });
    promises.push(getOffer(ev).then((offer) => {
      ev.directOffer = offer;
    }));
    return ev;
  }) ?? [];
  await Promise.all(promises);
  await addInitialEducationCountsToOffers(allVariants.value);
  await refreshAllPrices();

  const variant = allVariants.value[0];
  if (variant) {
    await selectVariant(variant);
  }
  loaded.value = true;
}

onMounted(async () => {
  await fetchData();
});

function getManagementInfoProduct(productId: number, serviceLevel: string, frequency: string, category: string, list: ManagementInformation0219[]): ManagementInformation0219|undefined {
  return list.find((x) => x.serviceReference_0219 === productId && x.serviceLevel_0219 === serviceLevel && x.managementInfoFrequency_0219 === frequency && x.managementInfoCategory_0219 === category);
}

function calcReady(directOffer: DirectOffer0219|undefined, variant: ExtendedVariant|undefined): boolean {
  const product = products.value.find((x) => x.id === variant?.productId);
  if (directOffer && variant && product) {
    const refIds = variant.selectedFunctionalAddOns?.filter((x) => x.serviceLevel_0219 === variant.serviceLevel_0219 && x.selected).map((x) => x.id) ?? [];
    refIds.push(variant.id);
    const test = relevantCalculationUnits(variant, product).every((n) => n.count) && directOffer.implementationType !== undefined
      && directOffer.educationCounts?.length === refIds.length
      && directOffer.educationCounts.every((n) => (n.trainTheTrainer ?? -1) >= 0 && (n.superuserEducation ?? -1) >= 0
      && (n.enduserEducation ?? -1) >= 0 && (n.enduserEducationRefresh ?? -1) >= 0 && n.refId && refIds.includes(n.refId));
    return test;
  }
  return false;
}

async function calculatePrice(directOffer: DirectOffer0219 | undefined, variant: ExtendedVariant): Promise<PriceResult> {
  if (directOffer) {
    return backend.directOfferService.getPrice(directOffer, priceParameterSet(variant));
  }
  const emptyResult: PriceResult = { };
  return emptyResult;
}

async function refreshAllPrices() {
  inconclusive.value = false;
  await Promise.all(allVariants.value.filter((v) => v.readyForCalculation()).map((v) => v.priceResultFunc())).then(() => allVariants.value.sort((a, b) => {
    const aPrice = a.priceResult?.price ?? 0;
    const bPrice = b.priceResult?.price ?? 0;
    const aServiceLevel = serviceLevelScore(a.serviceLevel_0219);
    const bServiceLevel = serviceLevelScore(b.serviceLevel_0219);
    if (aPrice < bPrice) {
      return -1;
    } if (aPrice > bPrice) {
      return 1;
    }
    // If price is equal, compare by serviceLevel
    if (bServiceLevel > aServiceLevel) {
      return -1;
    } if (bServiceLevel < aServiceLevel) {
      return 1;
    }
    // Unable to sort these two variants
    return 0;
  }));
  if (allVariants.value.length >= 2 && allVariants.value[0]?.priceResult?.price) {
    const firstPrice = allVariants.value[0].priceResult.price;
    const firstServiceLevel = allVariants.value[0].serviceLevel_0219;
    const countEqual = allVariants.value.filter((x) => x.priceResult?.price
            && x.priceResult.price === firstPrice
            && x.serviceLevel_0219 === firstServiceLevel).length;
    if (countEqual > 1 && allVariants.value.slice(0, countEqual - 1).map((x) => x.id).length > 1) {
      // if the top variants have equal price between different variants then we are unable to finalize and shows a pop-up and locks the view.
      console.log(`Unable to finalize - equal price across top ${countEqual} variants`);
      inconclusive.value = true;
      inconclusivePopup.value = true;
    }
  }
}

async function fetchQuestionnaire() {
  return backend.deepQuestionnaireService
    .getReadonlyQuestionnaire(
      tender.id,
      'specification',
    );
}

async function getOffer(variant:ExtendedVariant): Promise<DirectOffer0219 | undefined> {
  const product = products.value.find((p) => p.id === variant.productId);
  let supplier: OrganizationInfo|undefined;
  if (product && product.supplier && product.supplier.orgIDSupplier) {
    supplier = suppliers.find((x) => x.organizationId === product.supplier?.orgIDSupplier);
    if (supplier) {
      const newOffer: CreateDirectOfferRequest0219 = { tenderId: tender.id, supplier, variantId: variant.id };
      if (tender.state === TenderStateEnum.Evaluate) {
        // Get direct offer, create one if it does not exist
        return backend.directOfferService.createOffer<DirectOffer0219, CreateDirectOfferRequest0219>(newOffer);
      }
      // Get direct offer if it exists, do not create
      return backend.directOfferService.getOfferForTender<DirectOffer0219, CreateDirectOfferRequest0219>(newOffer);
    }
  }
  return undefined;
}

function relevantCalculationUnits(variant: ExtendedVariant, product: Product0219) {
  return productCalculationUnitsProp.value.filter((p) => product.calculationUnit_0219?.calculationUnitId_0219 === p.id
      || (variant.selectedFunctionalAddOns?.some((i) => i.calculationUnit_0219?.calculationUnitId_0219 === p.id && i.selected) ?? false)
      || (variant.selectedSupplierSpecificIntegrations?.some((i) => i.calculationUnit_0219?.calculationUnitId_0219 === p.id && i.selected) ?? false)
      || variant.managementInformationProduct?.calculationUnit_0219?.calculationUnitId_0219 === p.id
      || variant.commonManagementInformationProduct?.calculationUnit_0219?.calculationUnitId_0219 === p.id);
}

async function selectVariant(variant: ExtendedVariant) {
  loaded.value = false;
  const countType2 = allVariants.value.find((x) => x.directOffer?.implementationType === 'NewImplementation')?.directOffer?.educationCounts?.find((o) => o.area === service);
  if (countType2) {
    educationCountServiceType2.value = { ...countType2 };
    educationCountServiceType2.value.refId = undefined;
  }
  selectedVariant.value = variant;
  selectedProduct.value = products.value.first((x) => x.id === selectedVariant.value?.productId);
  if (selectedProduct.value.supplier?.orgIDSupplier && selectedVariant.value.id) {
    selectedOffer.value = variant.directOffer ?? null;
  }
  initialEducationCountsFT.value = selectedOffer.value?.educationCounts?.filter((o) => o.area !== service).map((x) => ({ ...x })) ?? [];
  initialEducationCountsSVC.value = { ...selectedOffer.value?.educationCounts?.find((o) => o.area === service) };

  await refreshAllPrices();
  loaded.value = true;
}

function allReady(variants: ExtendedVariant[]) {
  return variants.every((x) => x.readyForCalculation());
}

function bestVariant(variant: ExtendedVariant) {
  return allReady(allVariants.value) && isReadyForRiskAssessment(variant) && !inconclusive.value;
}

function isReadyForRiskAssessment(variant: ExtendedVariant): boolean {
  return variant.readyForCalculation() && variant.id === allVariants.value.filter((x) => x.directOffer?.riskAssessmentAccepted !== false)[0]?.id;
}

const unconditionalVariants = computed(() => allVariants.value.filter((v) => v.directOffer?.riskAssessmentAccepted === false));

const conditionalVariants = computed(() => allVariants.value.filter((v) => v.directOffer?.riskAssessmentAccepted !== false));

const isReadOnly = computed(() => tender.state !== TenderStateEnum.Evaluate);

function serviceLevelScore(serviceLevel: string | undefined) {
  switch (serviceLevel) {
    case 'Platin':
      return 4;
    case 'Guld':
      return 3;
    case 'Sølv':
      return 2;
    case 'Bronze':
      return 1;
    default:
      return 0;
  }
}

async function addInitialEducationCountsToOffers(variants: ExtendedVariant[]) {
  const request: BatchUpdateDirectOfferRequest<UpdateDirectOfferRequest0219> = { updateDirectOfferRequests: [] };
  variants.forEach((v) => {
    const offer = v.directOffer;
    if (offer) {
      const educationCountsServices = offer.educationCounts?.filter((x) => x.area === service) ?? [];
      let educationCountsService = offer.educationCounts?.find((x) => x.area === service);
      let changed = false;
      if (!educationCountsService || educationCountsServices.length > 1) {
        if (educationCountServiceType2.value) {
          educationCountsService = { ...educationCountServiceType2.value };
          educationCountsService.refId = v.id;
        } else {
          educationCountsService = { refId: v.id, area: service };
        }
        changed = true;
      }
      const educationCountsFTFromDbAll = offer.educationCounts?.filter((x) => x.area && x.area !== service) ?? [];
      const educationCountsFTFromDb = educationCountsFTFromDbAll.filter(
        (ec, index, array) => array.findIndex((t) => t.refId === ec.refId) === index,
      );
      if (educationCountsFTFromDbAll.length !== educationCountsFTFromDb.length) changed = true;
      const educationCountsFT:EducationCount[] = [];
      const selectedFA = v.selectedFunctionalAddOns?.filter((x) => x.serviceLevel_0219 === v.serviceLevel_0219 && x.selected) ?? [];
      selectedFA.forEach((a) => {
        const ec = educationCountsFTFromDb.find((x) => x.refId === a.id);
        if (ec) {
          educationCountsFT.push(ec);
        } else {
          educationCountsFT.push({ refId: a.id, area: `${a.functionalAddOnId_0219} ${a.name}` });
          changed = true;
        }
      });
      if (educationCountsFTFromDb.some((x) => !educationCountsFT.map((e) => e.refId).includes(x.refId))) changed = true;
      if (changed) {
        request.updateDirectOfferRequests.push({ id: offer.id, educationCounts: [educationCountsService].concat(educationCountsFT) });
      }
    }
  });
  const updatedOffers = await backend.directOfferService.updateDirectOfferBatch<DirectOffer0219, UpdateDirectOfferRequest0219>(request);
  updatedOffers.forEach((o) => {
    const variant = allVariants.value.find((v) => v.directOffer?.id === o.id);
    if (variant && variant.directOffer) {
      variant.directOffer.educationCounts = o.educationCounts;
    }
  });
}

</script>

<style scoped>
h2,
h3 {
  text-transform: uppercase;
}

.card, .card-selected {
  margin-bottom: 0.5em;
  padding: 0.5em 0.3em 0.5em 0.3em;
}

.card-selected {
  outline: 2px solid black; /* 'border-radius' not available */
}

ul {
  list-style-type: square;
}

.scroll {
  overflow-y: scroll;
  max-height: 80vh;
}

.total-price {
  font-size: medium;
}

.winner-tag {
  position: absolute;
  top: -12px;
  left: -12px;
  font-size: 35px;
}
</style>
